<template>
  <v-card flat class="pa-6">
    <template v-if="adm_id">
      <v-row v-if="!loading">
        <!-- Contas -->
        <v-col cols="12" v-if="contas.length">
          <v-card outlined>
            <v-card-title class="text-body-2 lightgray px-3 py-2">
              Contas
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="headerContas"
              :items="contas"
              :items-per-page="-1"
              :item-class="row_classes"
              class="data-tables "
              hide-default-footer
            >
              <template v-slot:item.data_vencimento="{ item }">
                {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
              </template>
              <template v-slot:item.data_pagamento="{ item }">
                {{ item.data_pagamento | dateFormat("dd/MM/yyyy") }}
              </template>
              <template v-slot:item.historico="{ item }">
                {{ item.historico | excerpt(40) }}
              </template>
              <template v-slot:item.valor="{ item }">
                {{ item.valor | _guarani }}
              </template>
              <template v-slot:item.valor_pago="{ item }">
                {{ item.valor_pago | _guarani }}
              </template>
              <template v-slot:item.desconto="{ item }">
                {{ item.desconto | _guarani }}
              </template>
              <template v-slot:item.saldo="{ item }">
                {{ item.saldo | _guarani }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="cliente_obs.length">
          <v-card outlined>
            <v-card-title class="text-body-2 lightgray pa-3">
              Cliente Observações
            </v-card-title>
            <v-divider></v-divider>
            <v-timeline dense class="pr-8">
              <v-slide-x-reverse-transition group hide-on-leave>
                <v-timeline-item
                  v-for="(obs, index) in cliente_obs"
                  :key="index"
                  color="lightgreen"
                  small
                >
                  <v-card outlined>
                    <v-card-title class="px-3 py-2 text-body-2 lightgray">
                      {{ obs.data | dateFormat("dd/MM/yyy") }}
                      - {{ obs.usuario }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-3 pb-0 text-caption text-justify">
                      <v-textarea
                        v-model="obs.obs"
                        auto-grow
                        solo
                        flat
                      ></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-card>
        </v-col>
      </v-row>
      <Carregando v-else />
    </template>
    <template v-else>
      Cliente Adm ID nao pode ser encontrado, por favor, cadastre um Adm ID na
      aba conexões.
    </template>
  </v-card>
</template>

<script>
import { getContas } from "@/services/api/contasreceber.api.js";
import { getClienteAdmObs } from "@/services/api/clientes.api.js";

export default {
  name: "ClienteFinanceiro",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
    adm_id: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      contas: [],
      cliente_obs: [],
    };
  },

  computed: {
    headerContas() {
      return [
        {
          text: "historico",
          value: "historico",
        },
        {
          text: "vencimento",
          value: "data_vencimento",
        },
        {
          text: "pagamento",
          value: "data_pagamento",
        },
        {
          text: "valor",
          align: "right",
          value: "valor",
        },
        // {
        //   text: "valor pago",
        //   align: "right",
        //   value: "valor_pago",
        // },
        // {
        //   text: "desconto",
        //   align: "right",
        //   value: "desconto",
        // },
        {
          text: "saldo",
          align: "right",
          value: "saldo",
        },
      ];
    },
  },

  methods: {
    row_classes(item) {
      if (Number(item.saldo) > 0) {
        return ["error--text", "font-weight-bold"]; //can also return multiple classes e.g ["orange","disabled"]
      }
    },

    async loadData() {
      this.loading = true;
      await getContas(`?cliente_adm_id=${this.adm_id}`)
        .then((response) => {
          this.contas = response;
        })
        .catch((error) => {
          console.log(error);
        });

      await getClienteAdmObs(this.adm_id)
        .then((response) => {
          this.cliente_obs = response;
        })
        .catch((error) => {
          console.log(error);
        });

      this.loading = false;
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
